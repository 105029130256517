import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { userContext } from '../../context/user'
import { FaShoppingBag, FaUserCircle } from 'react-icons/fa';
import { useCart } from '../../context/cart'
import API from '../../services/api'
import { toast } from 'react-toastify'
import CustomDropdown from '../utilities/CustomDropdown';
import { available_currencies, caption } from '../../datas';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {user, setUser} = useContext(userContext)
  const {cart, currency, setCurrency, baseCurrencyRate} = useCart()
  const [categories, setCategories] = useState()
  const [solutions, setSolutions] = useState()
  const navigate = useNavigate()
  const [profileOpen, setProfileOpen] = useState(false);
  const logout = () => {
    localStorage.removeItem('token')
    setUser(null)
    navigate('/login')
  }
  useEffect(()=>{
    async function getCategory(){
      try{
        const {data} = await API.get('/category')
        const res = await API.get('/solutions')
        setCategories(data.categories)
        setSolutions(res.data.solutions)
      }catch(error){
        toast.error(error.response?.data.message)
        console.log(error)
      }
    }
    getCategory()
  },[])
  const navigateCategory = (e) => {
    const selectedCategory = e.target.value;
    if(selectedCategory == "all"){
      navigate('/')
    }else{
    navigate(`/category/${selectedCategory}`)
    }
  }
  const navigateSolutions = (e) => {
    const selectedSolution = e.target.value;
    if(selectedSolution == "all"){
      navigate('/allproducts')
    }else{
    navigate(`/solutions/${selectedSolution}`)
    }
  }
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleCurrencyChange = (e)=> {
    setCurrency(e.target.value)
  }

  return (
    <>
      <div className="bg-green-900 text-white py-2 text-center text-sm z-50">
        {caption}
      </div>
      <nav className="bg-white shadow transition-all duration-100">
        <div className="container mx-auto px-4 flex justify-between items-center py-4">
          <Link to="/" className="text-green-800 text-2xl font-bold flex items-center">
            <img src="/images/logo.jpeg" alt="" className=' h-10' />
            <img src="/images/name.jpeg" alt="" className='h-10' />
          </Link>
          <div className="hidden md:flex md:items-center space-x-8">
            <Link to="/allproducts" className="text-gray-600 hover:text-green-800">All Products</Link>
            <div className="relative group z-50">
              <CustomDropdown menuOpen={isOpen} mainText="Shop by Category" data={categories} navigateHandler={navigateCategory} setMenuOpen={setIsOpen} />
            </div>
            <div className="relative group z-50">
              <CustomDropdown menuOpen={isOpen} mainText="Shop by Solution" data={solutions} navigateHandler={navigateSolutions} setMenuOpen={setIsOpen} />
            </div>
            <Link to="/blogs" className="text-gray-600 hover:text-green-800">Read Blogs</Link>
            <Link to="/about-us" className="text-gray-600 hover:text-green-800">About Us</Link>
          </div>
          <div className="flex space-x-4">
            <div className="flex items-center gap-4 text-gray-600 hover:text-green-800">
              <div className="hidden md:flex w-8 md:w-12 lg:w-16 flex-col relative group" onMouseEnter={() => setProfileOpen(true)} onMouseLeave={() => setProfileOpen(false)}>
                <FaUserCircle className='text-2xl' />
                {profileOpen ? user?
                  <div className=" w-24 border border-gray-300 rounded shadow-lg  mt-6 absolute bg-white z-50">
                  <Link to={"/profile"}>
                    <div className="py-2 px-1 lg:px-5 hover:bg-gray-100 cursor-pointer">Profile</div>
                  </Link>
                  <Link to={"/orders"}>
                    <div className="py-2 px-1 lg:px-5 hover:bg-gray-100 cursor-pointer">Orders</div>
                  </Link>
                  <button onClick={logout} className='w-full p-2 text-red-400 transition-all duration-200 hover:bg-red-500 hover:text-white'>Log out</button>
                </div>:
                <div className=" w-24 border border-gray-300 rounded shadow-lg  mt-6 absolute bg-white z-50">
                  <Link to={"/login"}>
                    <div className="py-2 px-1 lg:px-5 hover:bg-gray-100 cursor-pointer">Login</div>
                  </Link>
                  <Link to={"/register"}>
                    <div className="py-2 px-1 lg:px-5 hover:bg-gray-100 cursor-pointer">Sign Up</div>
                  </Link>
                </div>
                :""
                }
              </div>
              <div className="w-8 md:w-12 my-1 bg-transparent font-bold border-none outline-none">
              <select className='my-1 mx-2 bg-transparent font-bold border-none outline-none' value={currency} onChange={handleCurrencyChange}>
                <option value={available_currencies.INDIA}>INR</option>
                <option value={available_currencies.USA}>USD</option>
                <option value={available_currencies.EUROPE}>EUR</option>
                <option value={available_currencies.UAE}>AED</option>
              </select>
              </div>
              <Link to={"/cart"}>
              <div className=" relative">
                <FaShoppingBag className='text-3xl' />
                {cart?.length > 0 && <div className="absolute -top-1 -right-1 w-5 h-5 rounded-full bg-red-500 text-white text-center font-semibold">{cart?.length}</div>}
              </div>
              </Link>
            </div>
            <button onClick={toggleMenu} className="text-gray-600 text-2xl hover:text-green-800 md:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h18M3 12h18m-9 9h9" />
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden h-auto p-2">
            <CustomDropdown menuOpen={isOpen} mainText="Shop by Category" data={categories} navigateHandler={navigateCategory} setMenuOpen={setIsOpen} />
            <CustomDropdown menuOpen={isOpen} mainText="Shop by Solution" data={solutions} navigateHandler={navigateSolutions} setMenuOpen={setIsOpen} />
            {user == undefined ? <Link to={"/register"} className=" md:block px-4 py-2 text-gray-600 hover:text-green-800">Register</Link>:""}
            <Link to={"/blogs"} className="block px-4 py-2 text-gray-600 hover:text-green-800">Read Blogs</Link>
            <Link to={"/about-us"} className="block px-4 py-2 text-gray-600 hover:text-green-800">About Us</Link>
            {user && <button onClick={logout} className="block mx-3 px-4 py-1 bg-red-600 text-white rounded">Logout</button>}
          </div>
        )}
      </nav>
    </>
  );
};
export default Navbar
